import { useCallback, useEffect, useState } from 'react';

//import { useReCaptcha } from 'next-recaptcha-v3';
import api from 'services/api';

export const formStates = Object.freeze({
    INITIAL: 'INITIAL',
    SUBMITTING: 'SUBMITTING',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
    SUBSCRIBED: 'SUBSCRIBED',
});

const useSubscribe = (source, requireNewSubscriber = false) => {
    // const { executeRecaptcha, error, loaded } = useReCaptcha();

    const [formState, setFormState] = useState(formStates.INITIAL);

    const isError = formState === formStates.ERROR;
    const isSuccess = formState === formStates.SUCCESS;
    const isSubmitting = formState === formStates.SUBMITTING;
    const isSubscribed = formState === formStates.SUBSCRIBED;

    useEffect(() => {
        setFormState(formStates.INITIAL);
    }, []);

    const subscribe = useCallback(
        async formData => {
            if (isSubmitting) {
                return false;
            }

            setFormState(formStates.SUBMITTING);

            // if (!executeRecaptcha) {
            //     setFormState(formStates.ERROR);
            //     return false;
            // }

            // const token = await executeRecaptcha(source);

            // if (!token) {
            //     setFormState(formStates.ERROR);
            //     return false;
            // }

            try {
                // Required to set cookie & track browsing
                const timeout = new Promise(resolve => {
                    setTimeout(resolve, 500);
                });

                const identify = window.klaviyo.identify({
                    $email: formData?.email_address,
                });

                await Promise.race([identify, timeout]).then(value => {
                    console.log(value);
                });
            } catch (e) {}

            try {
                const { data } = await api.klaviyo.subscribe({
                    ...formData,
                    //  _token: token,
                    _source: source,
                    _requireNewSubscriber: requireNewSubscriber,
                });
                if (data?.success) {
                    setFormState(formStates.SUCCESS);
                    return data;
                } else {
                    if (data.message === 'SUBSCRIBED') {
                        setFormState(formStates.SUBSCRIBED);
                    } else {
                        setFormState(formStates.ERROR);
                    }
                    return false;
                }
            } catch (err) {
                // TODO add custom error message for all cases. BAD_EMAIL BAD_RECAPTCHA
                switch (err?.response?.data?.message) {
                    case 'SUBSCRIBED':
                        setFormState(formStates.SUBSCRIBED);
                        break;
                    default:
                        setFormState(formStates.ERROR);
                        break;
                }
            }
        },
        [source, requireNewSubscriber, isSubmitting]
    );

    return {
        isError,
        isSuccess,
        isSubmitting,
        isSubscribed,
        formState,
        subscribe,
    };
};

export default useSubscribe;
