'use client';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMedia } from 'react-use';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import cx from 'classnames';
import { usePathname } from 'next/navigation';
import PropTypes from 'prop-types';

import Logo from 'components/globals/Logo';
import { PreviousPathnameContext } from 'components/globals/PreviousPathnameProvider';

import Link from 'components/ui/Link';
import EntryLink from 'components/ui/Link/EntryLink';
import SvgIcon from 'components/ui/SvgIcon';

import useDetectTouch from 'hooks/useDetectTouch';
import useScrollDirection from 'hooks/useScrollDirection';

import CheeseburgerButton from './CheeseburgerButton';

import SearchBar from '../SearchBar';

import styles from './Header.module.scss';

const Header = ({ navigation, headerBottomSlot }) => {
    const rootRef = useRef();
    const isTouch = useDetectTouch();

    const items = navigation.itemsCollection.items;
    const splitIdx = Math.ceil(items.length / 2);
    const linksLeft = items.slice(0, splitIdx);
    const linksRight = items.slice(splitIdx, items.length);

    const [menuOpen, setMenuOpen] = useState(false);
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const [backFromSubPanel, setBackFromSubPanel] = useState(false);
    const [subMenuData, setSubMenuData] = useState(false);
    const [activeHoverIndex, setActiveHoverIndex] = useState(-1);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isHeaderHover, setIsHeaderHover] = useState(false);
    const previousPathname = useContext(PreviousPathnameContext);

    const isLarge = useMedia('(min-width: 1024px)', false);
    const scrollDirection = useScrollDirection();

    // hovering prevents nav hide
    const isHidden = (() => {
        if (menuOpen) {
            // prevent scroll closing menu when input focused and mobile keyboard scrolls page
            return false;
        }
        if (!isHeaderHover && activeHoverIndex < 0 && !isSearchOpen) {
            return scrollDirection === 'down';
        } else {
            return false;
        }
    })();

    const pathname = usePathname();
    const showIntro = pathname === '/' && !previousPathname;

    useEffect(() => {
        setMenuOpen(false);
        setSubMenuOpen(false);
        setBackFromSubPanel(false);
        setActiveHoverIndex(-1);
    }, [pathname, isLarge]);

    const handleLogoClick = e => {
        if (pathname === '/' && !menuOpen) {
            e.preventDefault();
            window.scrollTo({ top: 0 });
        }
    };

    const handleClickItem = (item, index) => {
        // mobile opens sub panel menu
        // large touch opens hover menu

        if (isLarge && isTouch) {
            setActiveHoverIndex(index);
            return;
        }

        setSubMenuData(item);
        setSubMenuOpen(true);
    };

    const handleBackClick = () => {
        setBackFromSubPanel(true);
        setSubMenuOpen(false);
    };

    const handleSearchIsOpen = isOpen => {
        setIsSearchOpen(isOpen);
    };

    const handleMouseEnter = index => {
        setActiveHoverIndex(index);
    };

    const handleMouseLeave = () => {
        setActiveHoverIndex(-1);
    };

    const handleHeaderMouseEnter = () => {
        isLarge && !isTouch && setIsHeaderHover(true);
    };

    const handleHeaderMouseLeave = () => {
        isLarge && !isTouch && setIsHeaderHover(false);
    };

    useEffect(() => {
        menuOpen
            ? disableBodyScroll(rootRef.current)
            : enableBodyScroll(rootRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuOpen]);

    const renderLinks = (items, navId) =>
        items.map((item, i) => (
            <li
                key={i}
                style={{ '--delay': `${(navId == 'left' ? i : i + 4) * 60}ms` }}
                className={cx(styles.navItem, {
                    [styles.hasChevron]:
                        item.link.__typename === 'ManualLink' &&
                        item?.itemsCollection.items.length > 0,
                    [styles.hasArrow]: item.link.__typename === 'ExternalLink',
                    [styles.isActive]: activeHoverIndex === `${navId}${i}`,
                    [styles.isTouch]: isTouch,
                })}
                onMouseEnter={() => {
                    handleMouseEnter(`${navId}${i}`);
                }}
                onMouseLeave={() => {
                    handleMouseLeave();
                }}
            >
                <EntryLink
                    entry={item.link}
                    onFocus={() => {
                        handleMouseEnter(`${navId}${i}`);
                    }}
                    onBlur={() => {
                        handleMouseLeave();
                    }}
                    className={styles.linkLarge}
                >
                    <span className={styles.linkInner}>{item.linkTitle}</span>
                </EntryLink>
                {item.link.__typename === 'ManualLink' &&
                    item.itemsCollection.items.length > 0 && (
                        <button
                            onClick={() => {
                                handleClickItem(item, `${navId}${i}`);
                            }}
                            aria-expanded={activeHoverIndex === `${navId}${i}`}
                            className={styles.linkSmall}
                        >
                            <span className={styles.linkInner}>
                                {item.linkTitle}
                            </span>
                        </button>
                    )}
                {item.itemsCollection.items &&
                    item.itemsCollection.items.length > 0 && (
                        <div className={styles.subNavContainer}>
                            <nav
                                className={styles.subNav}
                                onFocus={() => {
                                    handleMouseEnter(`${navId}${i}`);
                                }}
                                onBlur={() => {
                                    handleMouseLeave();
                                }}
                            >
                                <EntryLink entry={item.link}>
                                    {item.subMenuTitle}{' '}
                                    <div className={styles.circleArrowSolid}>
                                        <SvgIcon type="chevronRight" />
                                    </div>
                                </EntryLink>
                                {item.itemsCollection.items.map(
                                    (subItem, i) => (
                                        <EntryLink
                                            key={`sub-${i}`}
                                            entry={subItem.link}
                                        >
                                            {subItem.title}
                                            {subItem.new && (
                                                <span
                                                    className={
                                                        styles.isNewLabel
                                                    }
                                                >
                                                    NEW!
                                                </span>
                                            )}
                                        </EntryLink>
                                    )
                                )}
                            </nav>
                        </div>
                    )}
            </li>
        ));

    return (
        <div
            ref={rootRef}
            className={cx(styles.headerContainer, {
                [styles.menuOpen]: menuOpen,
                [styles.isHidden]: isHidden,
                [styles.isLanding]: showIntro,
            })}
        >
            <header
                className={styles.header}
                onMouseEnter={handleHeaderMouseEnter}
                onMouseLeave={handleHeaderMouseLeave}
            >
                <div
                    className={cx(styles.mobileLogoWrapper, {
                        [styles['mobileLogoWrapper--open']]: menuOpen,
                        [styles['mobileLogoWrapper--subPanelOpen']]:
                            subMenuOpen,
                        [styles['mobileLogoWrapper--backFromSubPanel']]:
                            backFromSubPanel,
                    })}
                >
                    <Link href="/">
                        <span onClick={handleLogoClick}>
                            <Logo withMorningStar className={styles.logoSvg} />
                            <span className="sr-only">Home</span>
                        </span>
                    </Link>
                </div>
                <nav
                    role="navigation"
                    data-overlay={true}
                    className={cx(styles.nav, {
                        [styles['nav--open']]: menuOpen,
                        [styles['nav--subPanelOpen']]: subMenuOpen,
                    })}
                >
                    <div
                        className={cx(styles.mobileLogoWrapperTop, {
                            [styles['mobileLogoWrapperTop--open']]: menuOpen,
                            [styles['mobileLogoWrapperTop--subPanelOpen']]:
                                subMenuOpen,
                            [styles['mobileLogoWrapperTop--backFromSubPanel']]:
                                backFromSubPanel,
                        })}
                    >
                        <Link href="/">
                            <Logo withMorningStar className={styles.logoSvg} />
                        </Link>
                    </div>
                    <div className={styles.mobileSubPanel}>
                        <button
                            className={cx(styles.navItem, styles.back)}
                            onClick={handleBackClick}
                        >
                            Back
                        </button>
                        {subMenuData?.itemsCollection?.items &&
                            subMenuData?.itemsCollection?.items?.length > 0 && (
                                <nav>
                                    <EntryLink
                                        entry={subMenuData.link}
                                        className={styles.mobileSubPanelLink}
                                    >
                                        {subMenuData.subMenuTitle}{' '}
                                        <div
                                            className={styles.circleArrowSolid}
                                        >
                                            <SvgIcon type="chevronRight" />
                                        </div>
                                    </EntryLink>
                                    {subMenuData.itemsCollection.items.map(
                                        (subItem, i) => (
                                            <EntryLink
                                                className={
                                                    styles.mobileSubPanelLink
                                                }
                                                key={`sub-${i}`}
                                                entry={subItem.link}
                                            >
                                                {subItem.title}
                                                {subItem.new && (
                                                    <span
                                                        className={
                                                            styles.isNewLabel
                                                        }
                                                    >
                                                        NEW!
                                                    </span>
                                                )}
                                            </EntryLink>
                                        )
                                    )}
                                </nav>
                            )}
                    </div>
                    <section className={styles.inner}>
                        <div className={styles.linksWrapper}>
                            <div className={styles.searchBarMobile}>
                                <SearchBar />
                            </div>
                            <ul className={styles.leftLinks}>
                                {renderLinks(linksLeft, 'left')}
                            </ul>
                            <ul className={styles.rightLinks}>
                                {renderLinks(linksRight, 'right')}
                            </ul>
                        </div>
                        <SearchBar
                            className={styles.searchBarDesktop}
                            onIsOpen={handleSearchIsOpen}
                        />
                    </section>
                </nav>
                <CheeseburgerButton
                    onClick={() => {
                        const open = !menuOpen;
                        setMenuOpen(open);
                        setBackFromSubPanel(false);
                        if (!open) {
                            setSubMenuOpen(false);
                        }
                    }}
                    showIntro={showIntro}
                    menuVisible={menuOpen}
                />
            </header>
            <div data-bottomslot>{headerBottomSlot}</div>
        </div>
    );
};

Header.propTypes = {
    navigation: PropTypes.object,
};

export default Header;
